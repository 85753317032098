<template>
  <v-card
    v-if="teams.length"
    color="white"
    width="100%"
    rounded="lg"
  >
    <v-item-group
      v-model="slotSelected"
      mandatory
    >
      <v-container>
        <v-card-text
          v-for="(team, indexTeams) in teams"
          :key="`team-${indexTeams}`"
          :class="{'pt-4': indexTeams === 0}"
        >
          <v-row class="px-0 py-1">
            <v-col
              cols="12"
              class="pa-1 d-flex justify-space-between align-center"
            >
              <span class="font-weight-bold black--text">{{ team.name }}</span>

              <v-btn
                v-if="showEdit && myTeam === team.id"
                class="pa-0 text-end no-bg-hover mr-1 text-body-1 font-weight-bold black--text"
                rounded
                text
                style="
                  text-transform: inherit;
                  min-width: 70px;
                "
                :ripple="false"
                @click="$emit('edit-players')"
              >
                {{ t('tooltip.edit') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-for="(player, index) in team.players"
            :id="`player-${player.slot}`"
            :key="`player-${player.slot}`"
            class="pa-0 pt-3 ma-0"
            :class="{ 'b-bottom': index !== team.length - 1, 'pt-4': index === 0 }"
          >
            <!-- remove team -->
            <!-- :class="{'cursor-pointer': player.empty && !disabled }" -->
            <v-item
              v-slot="{ active, toggle }"
              :value="player.slot"
              :class="{'cursor-pointer': (player.empty && player.selectable && !disabled) || (isJoined && team.my_team && player.empty)}"
            >
              <!-- remove team -->
              <!-- @click="() => { player.empty && !disabled ? toggle() : null }" -->
              <v-col
                cols="12"
                class="pa-0 d-flex justify-space-between align-center"
                @click="() => { player.empty && player.selectable && !isJoined && !disabled ? toggle() : isJoined && team.my_team && player.empty ? $emit('share') : null }"
              >
                <!-- :style="`opacity: ${player.empty && !player.selectable ? '0.7' : '1'};`" -->
                <div
                  class="d-flex align-center justify-space-between"
                  :style="`opacity: ${!player.empty || player.selectable || (player.empty && team.my_team) ? '1' : '0.7'};`"
                >
                  <v-badge
                    v-if="player.nprd !== null"
                    class="nprd-badge"
                    avatar
                    bottom
                    offset-x="20"
                    offset-y="20"
                    :color="resolveBadgeColor(player)"
                    :content="player.nprd > 0 ? `+${player.nprd}` : `${player.nprd}`"
                  >
                    <v-avatar
                      color="white"
                      :class="{ 'v-avatar-light-bg primary--text': !player.empty }"
                      :style="resolvePlayerStyle(player, active, team.id)"
                      size="50"
                    >
                      <v-img
                        v-if="player.avatar"
                        :src="player.avatar"
                      ></v-img>
                      <span
                        v-else-if="player.fullname"
                        class="font-weight-medium"
                      >{{ avatarText(player.fullname) }}</span>
                      <v-icon
                        v-else
                        :color="active ? '#14AE5C' : '#3f3f3f'"
                        class="ma-0"
                        size="25"
                      >
                        <!-- remove team -->
                        <!-- {{ active ? icons.mdiCheck : icons.mdiPlus }} -->
                        {{ active ? icons.mdiCheck : player.selectable || (player.empty && team.my_team) ? icons.mdiPlus : null }}
                      </v-icon>
                    </v-avatar>
                  </v-badge>

                  <v-avatar
                    v-else
                    color="white"
                    :class="{ 'v-avatar-light-bg primary--text': !player.empty }"
                    :style="resolvePlayerStyle(player, active, team.id)"
                    size="50"
                  >
                    <v-img
                      v-if="player.avatar"
                      :src="player.avatar"
                    ></v-img>
                    <span
                      v-else-if="player.fullname"
                      class="font-weight-medium"
                    >{{ avatarText(player.fullname) }}</span>
                    <v-icon
                      v-else
                      :color="active ? '#14AE5C' : '#3f3f3f'"
                      class="ma-0"
                      size="25"
                    >
                      <!-- remove team -->
                      <!-- {{ active ? icons.mdiCheck : icons.mdiPlus }} -->
                      {{ active ? icons.mdiCheck : player.selectable || (player.empty && team.my_team) ? icons.mdiPlus : null }}
                    </v-icon>
                  </v-avatar>

                  <div class="d-flex flex-column ml-2 justify-center">
                    <span
                      :class="{ 'black--text': !player.empty, 'font-weight-bold': player.empty && active }"
                    >
                      <!-- remove team -->
                      <!-- {{ player.fullname ? player.fullname : active ? $t('game.slot_selected') : $t('game.slot_empty') }} -->
                      <!-- {{ player.fullname ? player.fullname : active ? $t('game.slot_selected') : $t('game.available') }} -->
                      {{ player.fullname ? player.fullname : disabled ? '' : active ? $t('game.slot_selected') : $t('game.available') }}
                    </span>

                    <span
                      v-if="player.is_organizer === 'Y'"
                      class="text-xs"
                    >
                      {{ t('player_actions.organizer') }}
                    </span>
                  </div>
                </div>

                <div v-if="!player.empty">
                  <v-chip>
                    <img
                      v-if="player.paid === 'Y'"
                      :src="icons.moneyBill"
                      height="20px"
                      width="20px"
                      alt="moneyBill"
                      attrs="moneyBill"
                      style="background-position: center center; margin-right: 8px"
                    />
                    <span
                      class="text-sm"
                      :class="{ 'font-weight-bold': player.paid === 'Y' }"
                      :style="player.paid === 'N' ? { color: '#9b9b9b' } : {}"
                    >
                      {{ player.paid === 'Y' ? t('status.paid') : t('status.not_paid') }}
                    </span>
                  </v-chip>
                </div>

                <div v-if="showActions && false">
                  <player-action
                    v-if="false"
                    :show-remove="player.show_remove"
                    :show-leave="player.show_leave"
                    :show-add="player.show_add"
                    @add-guest="openCloseAddGuest(player.slot)"
                    @remove-player="onRemovePlayer(player.user_id, player.guest, player.slot)"
                    @leave-player="onLeave"
                  />

                  <v-chip v-if="player.showActions">
                    <v-icon
                      class="mr-2"
                      color="#FFBF00"
                    >
                      {{ icons.mdiSeal }}
                    </v-icon>
                    <span class="text-medium">
                      {{ player.seal }}
                    </span>
                  </v-chip>
                </div>
              </v-col>
            </v-item>
          </v-row>

          <v-divider
            v-if="indexTeams !== teams.length - 1"
            class="mt-7 mb-5"
            style="border-color: #9B9B9B"
          />
        </v-card-text>
      </v-container>
    </v-item-group>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  removePlayer, paidPlayer, editMatchInfo, leavePlayer, addGuest, removeGuest,
} from '@api'
import {
  mdiPlus,
  mdiSeal,
  mdiCheck,
  mdiSlashForward,
} from '@mdi/js'
import PlayerAction from '@/views/landing/components/player-action/PlayerAction.vue'

export default {
  name: 'PlayerList',
  components: {
    PlayerAction,
  },
  props: {
    teams: {
      type: Array,
      default: () => [],
      required: true,
    },
    playerQuantity: {
      type: Number,
      default: 0,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    isOrganizer: {
      type: Boolean,
      default: false,
    },
    isPlayer: {
      type: Boolean,
      default: false,
    },
    isJoined: {
      type: Boolean,
      default: false,
      required: true,
    },
    selected: {
      type: Number,
      default: 0,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    // remove chanllenge
    isChallenge: {
      type: Boolean,
      default: false,
    },
    myTeam: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()

    const slotSelected = computed({
      get: () => props.selected,
      set: value => emit('update-selected', value),
    })
    const isDialogChangePlayers = ref(false)
    const isDialogAddGuest = ref(false)
    const dataGuest = ref({
      guest_phone_number: null,
      guest_name: null,
    })

    const computedMatchCode = computed(() => JSON.parse(localStorage.getItem('matchCode')))
    const computedTitle = computed(() => props.title)

    const openClose = val => {
      isDialogChangePlayers.value = val
    }

    const resolvePlayerStyle = (player, active, teamId) => {
      if (player.its_me) return { border: '2px solid #14AE5C !important' }
      if (active && player.empty && !props.isJoined) return { border: '1.5px solid #14AE5C !important', backgroundColor: '#14ae5c40 !important' }
      if (!player.selectable && player.empty && props.isJoined && props.myTeam === teamId) return { border: '1.5px dashed #3f3f3f !important' }
      if (!player.selectable) return { border: '1.5px solid #3f3f3f !important' }
      if (player.empty) return { border: '1.5px dashed #3f3f3f !important' }

      return {}
    }

    const resolveBadgeColor = player => {
      if (player.nprd !== null) {
        if (player.nprd === 0) return '#FFBD1B'
        if (player.nprd > 0) return '#14AE5C'
        if (player.nprd < 0) return '#FF3232'
      }

      if (player.pr !== null) return '#3F3F3F'

      return '#3F3F3F'
    }

    const onChangeQuantity = async val => {
      const resp = await editMatchInfo(computedMatchCode.value, { match_players_qty: val })
      if (resp.ok) {
        isDialogChangePlayers.value = false
        emit('refresh-match')
      }
    }

    const openCloseAddGuest = val => {
      isDialogAddGuest.value = true
      dataGuest.value.slot = val
    }

    const onAddGuest = async val => {
      dataGuest.value.guest_name = val.guest_name
      dataGuest.value.guest_phone_number = val.guest_phone_number
      dataGuest.value.match_code = computedMatchCode.value

      const resp = await addGuest(dataGuest.value)
      if (resp.ok) {
        isDialogAddGuest.value = false
        emit('refresh-match')
      }
    }

    const onLeave = async () => {
      const resp = await leavePlayer(computedMatchCode.value)
      if (resp.ok) emit('refresh-match')
    }

    const onRemovePlayer = async (user, guest, slot) => {
      let resp = null
      if (guest) resp = await removeGuest(computedMatchCode.value, slot)
      else resp = await removePlayer(computedMatchCode.value, user)

      if (resp.ok) emit('refresh-match')
    }

    const onPayPlayer = async (paid, slot, isOrganizer) => {
      if (isOrganizer) {
        const resp = await paidPlayer(computedMatchCode.value, {
          match_player_paid: paid,
          slot,
        })

        if (resp.ok) emit('refresh-match')
      }
    }

    return {
      // data
      isDialogChangePlayers,
      isDialogAddGuest,
      slotSelected,

      // computed
      computedTitle,

      // methods
      openClose,
      onChangeQuantity,
      openCloseAddGuest,
      onAddGuest,
      onRemovePlayer,
      onPayPlayer,
      onLeave,
      avatarText,
      resolvePlayerStyle,
      resolveBadgeColor,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiSeal,
        mdiCheck,
        mdiSlashForward,
        moneyBill: require('@/assets/images/svg/money-bill.svg'),
      },
    }
  },
}
</script>

<style lang="scss">
.nprd-badge .v-badge__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 800;
  min-width: 22px;
  height: 22px;
}
</style>
