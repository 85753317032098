<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
        @click="showArrow ? $emit('save') : null"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
          :class="{ 'cursor-pointer': showArrow, 'cursor-default': !showArrow }"
          :style="showBorder ?
            `border: 1px solid rgba(255, 50, 50, 0.50) !important; box-shadow: 0px 4px 4px 0px rgba(255, 50, 50, 0.25) !important;`
            : ''
          "
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div class="d-flex">
              <v-avatar
                :style="`background-color: ${avatarColor} !important;`"
                class="v-avatar-light-bg primary--text mr-3"
                size="50"
              >
                <div
                  v-if="hasIconContent"
                  :style="iconContainerStyles"
                >
                  <slot name="icon"></slot>
                </div>
                <v-icon
                  v-else
                  :color="iconColor"
                  class="ma-0"
                  size="25"
                >
                  {{ icons.mdiVideoOutline }}
                </v-icon>
              </v-avatar>

              <div class="d-flex flex-column">
                <span class="font-weight-bold">{{ title }}</span>
                <span
                  class="mt-1"
                  style="color: #3f3f3f;"
                >
                  {{ subtitle }}
                </span>
              </div>
            </div>

            <v-icon
              v-if="showArrow"
              color="#9b9b9b"
              class="ma-0"
              size="25"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiVideoOutline,
  mdiMessageText,
  mdiSeal,
  mdiChevronRight,
} from '@mdi/js'
import { computed, useSlots } from '@vue/composition-api'

export default {
  name: 'ActionCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    avatarColor: {
      type: String,
      default: 'transparent',
    },
    iconColor: {
      type: String,
      default: '#ffffff',
    },
    iconContainerStyles: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '#FF3232',
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const slots = useSlots()

    const hasIconContent = computed(() => !!slots.icon)

    return {
      hasIconContent,

      icons: {
        mdiVideoOutline,
        mdiMessageText,
        mdiSeal,
        mdiChevronRight,
      },
    }
  },
}
</script>
