<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text class="pt-md-0 pt-sm-6">
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ $t('game.match_detail') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin(false)"
                >
                  {{ $t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text
        v-if="cancelledComputed"
        class="pt-0"
      >
        <action-card
          :title="$t('game.match_cancelled')"
          :subtitle="$t('game.match_has_been_cancelled')"
        >
          <template #icon="{ on, attrs }">
            <v-icon
              color="#ff3232"
              class="ma-0"
              size="25"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </template>
        </action-card>
      </v-card-text>

      <v-card-text class="pt-0">
        <info-card
          :title="dataComputed.sport_name"
          :subtitle="dataComputed.match_date_str"
          :date="`${formatStdDate(dataComputed.match_date_str, 'datetime-landing', true, $i18n.locale)} ${formatStdDate(dataComputed.start_time, 'time-short', true, $i18n.locale)} - ${formatStdDate(dataComputed.end_time, 'time-short', true, $i18n.locale)}`"
          :location="dataComputed.facility_name"
          :level="dataComputed.match_level_str === 'all' ? $t('game.any_level') : dataComputed.match_level_str"
          :price="dataComputed && dataComputed.total > 0 ? `${formatCurrency(dataComputed.total)}` : $t('status.free')"
          :gender-category="dataComputed.match_gender_str"
          :start-time="dataComputed.start_time_formatted ? `${dataComputed.start_time_formatted} (${formatTime(dataComputed.reservation_time)})` : ''"
          :cancel-date="dataComputed.cancel_reservation_date_str"
          :is-cancel="dataComputed.reservation_status === 'cancelled'"
          :can-cancel="dataComputed.can_cancel"
          :is-confirm="!!dataComputed.deposit_card"
          :confirm-code="depositCardComputed"
          :confirm-date="dataComputed.cancel_reservation_date_str"
          :is-paid="dataComputed.is_paid === 'Y'"
          :is-now="dataComputed.charge_reservation_date_str === 'now'"
          :payment-status="dataComputed.reservation_status === 'cancelled' ? dataComputed.reservation_status : dataComputed.payment_status"
          :disabled="cancelledComputed"
        />
      </v-card-text>

      <v-card-text
        v-if="dataComputed && dataComputed.match_status === 'P'"
        class="pt-0"
      >
        <results-card
          :my-team-name="dataComputed.my_team === null || dataComputed.my_team === 'A' ? getTeamName(dataComputed.teams || [], 'A', dataComputed.my_team) : getTeamName(dataComputed.teams || [],'B', dataComputed.my_team)"
          :rival-team-name="dataComputed.my_team === null || dataComputed.my_team === 'A' ? getTeamName(dataComputed.teams || [], 'B', dataComputed.my_team) : getTeamName(dataComputed.teams || [],'A', dataComputed.my_team)"
          :my-team-result="dataComputed.my_team === null || dataComputed.my_team === 'A' ? dataComputed.match_result_A : dataComputed.match_result_B"
          :rival-team-result="dataComputed.my_team === null || dataComputed.my_team === 'A' ? dataComputed.match_result_B : dataComputed.match_result_A"
          :my-team-shield="dataComputed.my_team === null || dataComputed.my_team === 'A' ? getTeamShield(dataComputed.teams || [], 'A', dataComputed.my_team) : getTeamShield(dataComputed.teams || [],'B', dataComputed.my_team)"
          :rival-team-shield="dataComputed.my_team === null || dataComputed.my_team === 'A' ? getTeamShield(dataComputed.teams || [], 'B', dataComputed.my_team) : getTeamShield(dataComputed.teams || [],'A', dataComputed.my_team)"
        />
      </v-card-text>

      <v-card-text
        v-if="dataComputed && dataComputed.can_add_result && !cancelledComputed"
        class="pt-0"
      >
        <action-card
          :title="$t('game.enter_results')"
          :subtitle="$t('game.enter_results_msg')"
          avatar-color="#ff3030"
          icon-color="#ffffff"
          :show-border="!!userLogged"
          border-color="#ff323240"
          :show-arrow="!!userLogged"
          @save="!!userLogged && dataComputed.is_joined ? linkVideo(false) : null"
        >
          <template #icon="{ attrs }">
            <img
              :src="icons.seal"
              height="20px"
              width="20px"
              alt="video"
              attrs="video"
              style="background-position: center center; margin-top: 4px"
              v-bind="attrs"
            />
          </template>
        </action-card>
      </v-card-text>

      <!-- TODO deek to video_detail -->
      <v-card-text
        v-if="dataComputed && dataComputed.video_id"
        class="pt-0"
      >
        <video-card
          :images="dataComputed.video_thumbnail ? [dataComputed.video_thumbnail] : []"
          @open="linkVideo(true)"
        />
      </v-card-text>

      <v-card-text
        v-if="!cancelledComputed"
        class="pt-0"
      >
        <!-- // remove team -->
        <!-- is-challenge, my-team -->
        <!-- :show-edit="dataComputed.can_edit_players && false" -->
        <player-list
          :teams="teamsComputed"
          :player-quantity="dataComputed.match_players_qty"
          :show-actions="true"
          :show-edit="dataComputed.is_organizer === 'Y'"
          :is-private="privateLanding"
          :is-organizer="dataComputed.is_organizer === 'Y'"
          :is-player="dataComputed.is_player"
          :is-joined="dataComputed.is_joined"
          :is-challenge="dataComputed.is_challenge === 'Y'"
          :my-team="dataComputed.my_team"
          :selected.sync="slotSelected"
          :disabled="computedDisabled"
          @refresh-match="fetchMatch"
          @download-app="openUpdateDownload"
          @open-game="linkVideo"
          @update-selected="(val) => slotSelected = val"
          @edit-players="linkVideo"
          @share="openUpdateShare(true)"
        />
      </v-card-text>

      <v-card-text v-if="false">
        <action-card
          :title="'Chat del partido'"
          :subtitle="'Todos los jugadores participan del chat'"
          :show-arrow="true"
        >
          <template #icon="{ attrs }">
            <v-icon
              color="white"
              class="ma-0"
              size="25"
              v-bind="attrs"
            >
              {{ icons.mdiMessageText }}
            </v-icon>
          </template>
        </action-card>
      </v-card-text>

      <v-card-text
        v-if="!cancelledComputed"
        class="pt-0"
      >
        <action-card
          :title="dataComputed.match_type === 'competitive' ? $t('game.ranked') : $t('game.not_ranked')"
          :subtitle="dataComputed.match_type === 'competitive' ? $t('game.ranked_msg') : $t('game.not_ranked_msg')"
          :avatar-color="dataComputed.match_type === 'competitive' ? '#14AE5C40' : '#F2F2F2'"
          :icon-container-styles="'width: 25px; height: 27px;'"
        >
          <template #icon="{ attrs }">
            <img
              :src="dataComputed.match_type === 'competitive' ? icons.levelRanked : icons.level"
              height="20px"
              width="20px"
              alt="level"
              attrs="level"
              style="background-position: center center"
              v-bind="attrs"
            />
          </template>
        </action-card>
      </v-card-text>

      <v-card-text
        v-if="dataComputed.include_video === 'Y' && !cancelledComputed"
        class="pt-0"
      >
        <action-card
          :title="$t('game.with_video')"
          :subtitle="$t('game.with_video_msg')"
          avatar-color="#14AE5C40"
          icon-color="#14AE5C"
        >
          <template #icon="{ attrs }">
            <img
              :src="icons.video"
              height="20px"
              width="20px"
              alt="video"
              attrs="video"
              style="background-position: center center; margin-top: 4px"
              v-bind="attrs"
            />
          </template>
        </action-card>
      </v-card-text>

      <v-card-text
        style="padding-bottom: 140px;"
        class="pt-0"
      >
        <facility-card
          :facility-name="dataComputed.facility_name"
          :facility-address="dataComputed.facility_short_address"
          :field-name="dataComputed.field_name"
          :images="dataComputed.facility_images"
          :latitude="dataComputed.facility_latitude"
          :longitude="dataComputed.facility_longitude"
          :disabled="cancelledComputed"
        />
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          v-if="(dataComputed.can_join || (dataComputed.is_joined && !dataComputed.paid) && dataComputed.match_status !== 'C')"
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="onPayBooking"
        >
          <!-- // remove team -->
          <!-- <span v-if="dataComputed.is_joined && !dataComputed.paid && dataComputed.match_status !== 'C'">{{ `${$t('game.pay_my_part')}` }}</span> -->
          <span v-if="dataComputed.is_challenge == 'Y' && dataComputed.can_join_free">{{ $t('deek_game.join') }}</span>
          <span v-else-if="dataComputed.is_joined && !dataComputed.paid && dataComputed.match_status !== 'C'">{{ `${$t('game.pay_my_part')}` }}</span>
          <span v-else-if="dataComputed && dataComputed.total > 0">{{ `${$t('deek_game.join_for')} ${formatCurrency(dataComputed.total)}` }}</span>
          <span v-else>{{ $t('deek_game.join') }}</span>
        </v-btn>

        <!-- :outlined="!(dataComputed.is_joined && ['N', 'F'].includes(dataComputed.match_status)) && dataComputed.match_status !== 'C'" -->
        <v-btn
          v-else
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          :outlined="((dataComputed.can_join || (dataComputed.is_joined && !dataComputed.paid) && dataComputed.match_status !== 'C'))"
          @click="openUpdateShare(true)"
        >
          <span>{{ $t('game.share_invite') }}</span>
        </v-btn>

        <v-btn
          v-if="dataComputed.can_leave && dataComputed.match_status !== 'C'"
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          outlined
          @click="openUpdateLeave(true)"
        >
          <span>{{ $t('game.unjoin') }}</span>
        </v-btn>
      </v-card-actions>
    </div>

    <download-app
      :is-open.sync="isDialogDownload"
      @open-close="openUpdateDownload"
      @save="defaultDownloadAppByOS"
    />

    <share
      :is-open.sync="isDialogShare"
      :link="dataComputed.match_share_url"
      :icon-size="28"
      :sport="dataComputed.sport_name"
      :date="dataComputed.match_date_str"
      :start-time="`${formatStdDate(dataComputed.start_time, 'time', true, $i18n.locale)} (${dataComputed.match_time} Mins)`"
      :location="dataComputed.facility_name ? `${dataComputed.facility_name} (${dataComputed.field_name})` : ''"
      @open-close="openUpdateShare"
    />

    <joined-match
      :is-open.sync="isDialogJoined"
      :title="joinedMessageFrom === 'pay-booking' ? $t('game.joined_match') : $t('game.joined_reservation')"
      :subtitle="joinedMessageFrom === 'pay-booking' ? $t('game.share_link') : $t('game.share_link_reservation')"
      :notify-new-slot="notifyNewSlot"
      :new-slot-message="$t('game.notify_new_slot')"
      @open-close="openUpdateJoined"
      @save="() => {
        openUpdateJoined(false)
        openUpdateShare(true)
        notifyNewSlot = false
      }"
    />

    <leave-match
      :is-open.sync="isDialogLeave"
      @open-close="openUpdateLeave"
      @save="onLeave"
    />
  </div>
</template>
<script>
import {
  mdiTrayArrowUp,
  mdiDotsVertical,
  mdiCircle,
  mdiMapMarker,
  mdiCancel,
  mdiCloseCircleOutline,
  mdiPlay,
  mdiStarOutline,
  mdiVideo,
  mdiPencil,
  mdiMovieOpenPlayOutline,
  mdiHelpCircleOutline,
  mdiClose,
  mdiVideoOutline,
  mdiMessageText,
  mdiSeal,
} from '@mdi/js'
import {
  ref, computed, watch, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import {
  useRouter,
  openTab,
  defaultDownloadAppByOS,
  isDev,
  formatCurrency,
  formatStdDate,
  customDeepLink,
} from '@core/utils'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import {
  logUserActivity, getMatchGameCode, getMatchGameOpenCode, putHoldCardReservation, leaveMatchUser, joinMatchUser,
} from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import {
  showSuccess, showError,
} from '@/@core/utils/toasted'
import store from '@/store'

import JoinedMatch from '@/views/game/components/joined-match/JoinedMatch.vue'
import LeaveMatch from '@/views/game/components/leave-match/LeaveMatch.vue'
import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import InfoCard from '@/views/game/components/info-card/InfoCard.vue'
import ActionCard from '@/views/game/components/action-card/ActionCard.vue'
import ResultsCard from '@/views/game/components/results-card/ResultsCard.vue'
import VideoCard from '@/views/game/components/video-card/VideoCard.vue'
import PlayerList from '@/views/game/components/player-list/PlayerList.vue'
import FacilityCard from '@/views/game/components/facility-card/FacilityCard.vue'
import DownloadApp from '@/views/reservation/components/download-app/DownloadApp.vue'
import Share from '@/views/components/share/Share.vue'

import useGame from './useGame'

export default {
  name: 'Game',
  components: {
    AppBarUserAvatar,
    PlayerList,
    DownloadApp,
    InfoCard,
    ActionCard,
    ResultsCard,
    VideoCard,
    FacilityCard,
    Share,
    JoinedMatch,
    LeaveMatch,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { route, router } = useRouter()
    const { t, tInContext } = useUtils()
    const { userData } = useCryptoJs()
    const { logData, updateLogData, clearLogData } = useLogData()
    const {
      paymentMethods, mapTeams, getTeamName, getTeamShield,
    } = useGame()

    const USER_ACT_VISIT_MATCH_LANDING_APP = 'Visit Match Landing App'
    const USER_ACT_SHARE_MATCH_LANDING_APP = 'Share Match Landing App'
    const USER_ACT_VIEW_MATCH_LANDING_APP = 'View Match Landing App'
    const ipAddress = ref({})
    const slotSelected = ref(-1)
    const notifyNewSlot = ref(false)
    const joinedMessageFrom = ref(null)
    const userLogged = ref(null)

    const dataLanding = ref({})
    const dataComputed = computed(() => dataLanding.value)
    const computedDisabled = computed(() => (dataComputed.value.is_challenge === 'Y' && (dataComputed.value.is_joined || !dataComputed.value.can_join)) || (dataComputed.value.is_challenge === 'N' && (dataComputed.value.is_joined || dataComputed.value.match_status !== 'N')))
    const showComplexComputed = computed(
      () => dataComputed.value.complex_images
        && dataComputed.value.complex_images.length
        && dataComputed.value.complex_description,
    )
    const activeComputed = computed(() => dataComputed.value.match_status === 'Active')
    const basicComputed = computed(() => dataComputed.value.match_status === 'Basic')
    const cancelledComputed = computed(() => dataComputed.value.match_status === 'Cancelled')
    const depositCardComputed = computed(() => {
      if (dataComputed.value.deposit_card && dataComputed.value.deposit_card.ending4) return dataComputed.value.deposit_card.ending4

      return ''
    })
    const matchCode = ref(null)
    const matchId = ref(null)
    const videoId = ref(null)
    const privateLanding = ref(false)
    const isDialogLeave = ref(false)
    const isDialogJoined = ref(false)
    const isDialogDownload = ref(false)
    const isDialogPay = ref(false)
    const isDialogShare = ref(false)

    // remove chanllenge
    const teamSelected = ref(null)
    const teamsComputed = computed(() => {
      if (!dataComputed.value.players_team_a && !dataComputed.value.players_team_b) return []

      const myTeamsss = mapTeams(
        dataComputed.value.players_team_a,
        dataComputed.value.players_team_b,
        dataComputed.value.is_challenge === 'Y' ? teamSelected : dataComputed.value.my_team,
        dataComputed.value.my_team,
        dataComputed.value.teams || [],
      )

      return myTeamsss
    })

    const logged = computed({
      get: () => store.state.auth.logged,
      set: value => {
        store.commit('appConfig/UPDATE_FOOTER_TYPE', value)
      },
    })

    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const openUpdatePay = async val => {
      isDialogPay.value = val
    }

    const openUpdateLeave = async val => {
      isDialogLeave.value = val
    }

    const openUpdateJoined = async val => {
      isDialogJoined.value = val
    }

    const openUpdateDownload = async val => {
      isDialogDownload.value = val
    }

    const goToPayBooking = () => {
      router.push({ name: 'pay-booking', params: { code: matchCode.value, selected: slotSelected.value, team: teamSelected.value } })
    }

    const goToProfilePicture = () => {
      router.push({
        name: 'profile-picture',
        params: {
          code: matchCode.value,
          selected: slotSelected.value,
          team: teamSelected.value,
          joined: true,
          joinedFrom: 'pay-booking',
        },
      })
    }

    const fetchMatch = async () => {
      let response = null
      if (userLogged.value) {
        if (teamSelected.value) {
          response = await getMatchGameCode(matchCode.value, teamSelected.value)
        } else {
          response = await getMatchGameCode(matchCode.value)
        }
      } else if (teamSelected.value) {
        response = await getMatchGameOpenCode(matchCode.value, teamSelected.value)
      } else {
        response = await getMatchGameOpenCode(matchCode.value)
      }
      if (response.ok) {
        dataLanding.value = response.data
        const {
          joined, joinedMessage, joinedFrom, selected, pay,
        } = route.value.params

        // remove team dataLanding.value.is_challenge === 'N' &&
        if (dataLanding.value.is_challenge === 'N' && userLogged.value && pay && dataLanding.value.can_join) {
          slotSelected.value = selected

          // remove team
          goToPayBooking()
        }

        // remove team
        if (dataLanding.value.is_challenge === 'Y' && userLogged.value && pay && !dataLanding.value.is_joined) {
          // remove team
          if (!dataLanding.value.can_join_free) goToPayBooking()

          if (dataLanding.value.can_join_free) {
            const resp = await joinMatchUser(matchCode.value, { slot: selected })

            // eslint-disable-next-line no-use-before-define
            if (resp.ok) await initGame()
          }
        }

        if (joined && dataLanding.value.is_joined) {
          notifyNewSlot.value = joinedMessage
          joinedMessageFrom.value = joinedFrom
          openUpdateJoined(true)
        }
        matchId.value = dataLanding.value.match_id
        videoId.value = dataLanding.value.video_id

        // remove chanllenge
        if (!dataLanding.value.is_joined) {
          if (dataLanding.value.is_challenge === 'Y') {
            let selectSlot = false
            if (teamSelected.value === 'A') {
              dataLanding.value.players_team_a.forEach(e => {
                if (e.empty) {
                  e.selectable = true
                  if (!selectSlot) {
                    selectSlot = true
                    e.selected = true
                  }
                } else e.selectable = false
              })
              // eslint-disable-next-line no-return-assign
              dataLanding.value.players_team_b.map(e => e.selectable = false)
            } else if (teamSelected.value === 'B') {
              dataLanding.value.players_team_b.forEach(e => {
                if (e.empty) {
                  e.selectable = true
                  if (!selectSlot) {
                    selectSlot = true
                    e.selected = true
                  }
                } else e.selectable = false
              })
              // eslint-disable-next-line no-return-assign
              dataLanding.value.players_team_a.map(e => e.selectable = false)
            } else {
              dataLanding.value.players_team_a.forEach(e => {
                if (e.empty) {
                  e.selectable = true
                  if (!selectSlot) {
                    selectSlot = true
                    e.selected = true
                  }
                } else e.selectable = false
              })
              dataLanding.value.players_team_b.forEach(e => {
                if (e.empty) {
                  e.selectable = true
                  if (!selectSlot) {
                    selectSlot = true
                    e.selected = true
                  }
                } else e.selectable = false
              })
            }
          } else {
            // eslint-disable-next-line array-callback-return
            dataLanding.value.players_team_a.map(e => {
              if (e.empty) e.selectable = true
              else e.selectable = false
            })
            // eslint-disable-next-line array-callback-return
            dataLanding.value.players_team_b.map(e => {
              if (e.empty) e.selectable = true
              else e.selectable = false
            })
          }
        }

        // remove chanllenge
        if (!computedDisabled.value) {
          const finded = [...dataLanding.value.players_team_a, ...dataLanding.value.players_team_b].find(e => e.selected)
          slotSelected.value = finded && !dataLanding.is_joined ? finded.slot : -1
        }
      } else {
        router.replace({
          name: 'match-not-found',
          params: {
            inactive: false,
            invalid: true,
          },
        })
      }
    }

    const onLogin = async (pay = false) => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value

      logData.value.slot = slotSelected.value
      logData.value.pay = pay
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'

      // remove team
      logData.value.team = teamSelected.value
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const fetchLogUserActivity = async action => {
      await logUserActivity({
        ip_address: privateLanding.value ? null : ipAddress.value.ip,
        user_id: privateLanding.value ? userData.value.user_id : null,
        match_code: matchCode.value,
        reference: null,
        action,
      })
    }

    const linkVideo = (isVideo = false) => {
      customDeepLink(
        isVideo ? 'video' : 'match',
        isVideo ? videoId.value : matchId.value,
      )

      openUpdateDownload(true)
    }

    const formatTime = time => {
      const [min] = time.split('mins')

      return `${min} mins`
    }

    const onPay = async value => {
      const resp = await putHoldCardReservation(matchCode.value, value)
      if (resp.ok) {
        openUpdatePay(false)
        await fetchMatch()
      } else showError(resp.message.text)
    }

    const initGame = async () => {
      await clearLogData()

      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      privateLanding.value = !!userLogged.value

      // remove team
      const { code, team } = route.value.params

      matchCode.value = code

      // remove chanllenge
      teamSelected.value = `${team}`.toUpperCase()
      localStorage.setItem('matchCode', JSON.stringify(code))

      await fetchMatch()

      if (!userLogged.value) {
        await fetch('https://api.ipify.org/?format=json')
          .then(ipResponse => ipResponse.json())
          .then(async ipData => {
            ipAddress.value = ipData
          })
      }
    }

    const onPayBooking = async () => {
      if (privateLanding.value) {
        // remove team
        if (dataComputed.value.can_join_free) {
          const resp = await joinMatchUser(matchCode.value, { slot: slotSelected.value })
          if (resp.ok) {
            if (userData.value.avatar === null || userData.value.avatar.includes('no-profile-pic')) {
              goToProfilePicture()
            } else await initGame()
          }
        } else {
          goToPayBooking()
        }
      } else {
        onLogin(true)
      }
    }

    const onLeave = async () => {
      const resp = await leaveMatchUser(matchCode.value)
      const msg = tInContext('game.leaved_match', vm)
      if (resp.ok) {
        await initGame()
        openUpdateLeave(false)
        showSuccess(msg)
      } else {
        showError(resp.message.text)
      }
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      paymentMethods.value = []

      await initGame()
    }

    watch([logged], async () => {
      await initGame()
    })

    onMounted(async () => {
      await initGame()
    })

    return {
      USER_ACT_VISIT_MATCH_LANDING_APP,
      USER_ACT_SHARE_MATCH_LANDING_APP,
      USER_ACT_VIEW_MATCH_LANDING_APP,
      ipAddress,
      userData,
      isDev,
      paymentMethods,
      matchId,
      videoId,
      slotSelected,

      // remove chanllenge
      teamSelected,
      notifyNewSlot,
      joinedMessageFrom,
      userLogged,
      teamsComputed,

      matchCode,
      privateLanding,
      isDialogLeave,
      isDialogJoined,
      isDialogDownload,
      isDialogPay,
      isDialogShare,

      dataComputed,
      computedDisabled,
      depositCardComputed,
      showComplexComputed,
      activeComputed,
      basicComputed,
      cancelledComputed,

      appLogoTitle: themeConfig.app.logoTitleBlack,

      avatarText,
      openTab,
      onLogin,
      linkVideo,
      customDeepLink,
      fetchMatch,
      openUpdateDownload,
      openUpdatePay,
      openUpdateShare,
      openUpdateLeave,
      openUpdateJoined,
      fetchLogUserActivity,
      onLogout,
      onPayBooking,
      onPay,
      defaultDownloadAppByOS,
      formatTime,
      formatCurrency,
      mapTeams,
      getTeamName,
      getTeamShield,
      formatStdDate,
      onLeave,

      t,

      icons: {
        mdiTrayArrowUp,
        mdiDotsVertical,
        mdiCircle,
        mdiMapMarker,
        mdiCancel,
        mdiCloseCircleOutline,
        mdiPlay,
        mdiStarOutline,
        mdiVideo,
        mdiPencil,
        mdiMovieOpenPlayOutline,
        mdiHelpCircleOutline,
        mdiClose,
        mdiVideoOutline,
        mdiMessageText,
        mdiSeal,
        level: require('@/assets/images/svg/level.svg'),
        levelRanked: require('@/assets/images/svg/level-ranked.svg'),
        video: require('@/assets/images/svg/video.svg'),
        seal: require('@/assets/images/svg/seal.svg'),
        teamShield: require('@/assets/images/svg/team-shield.svg'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>
