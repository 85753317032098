import { ref, getCurrentInstance } from '@vue/composition-api'
import { getUserPaymentMethods } from '@api'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useGame() {
  const vm = getCurrentInstance().proxy
  const { decryptData } = useCryptoJs()
  const { tInContext } = useUtils()

  const paymentMethods = ref([])

  const fetchPaymentMethods = async () => {
    try {
      const response = await getUserPaymentMethods()
      if (response.ok) {
        paymentMethods.value = decryptData(response.data)
        paymentMethods.value.unshift({
          id: 0,
          last_4: null,
          exp_month: null,
          exp_year: null,
          brand: 'Add new card',
          exp_date_str: null,
        })
      }
    } catch (e) {
      console.error(e)
      paymentMethods.value = []
    }
  }

  const sortByNprd = array => array.sort((a, b) => {
    const nprdA = a.nprd === null ? 0 : a.nprd
    const nprdB = b.nprd === null ? 0 : b.nprd

    return nprdB - nprdA
  })

  const getTeamsName = (myTeams, myTeam, hasTeam = null) => {
    const myTeamA = myTeams.find(e => e.letter === 'A')
    const myTeamB = myTeams.find(e => e.letter === 'B')

    let teamAName = myTeamA ? myTeamA.name : `${tInContext('game.team', vm)} A`
    let teamBName = myTeamB ? myTeamB.name : `${tInContext('game.team', vm)} B`

    const teamShield = require('@/assets/images/svg/team-shield.svg')
    let teamAShield = myTeamA ? myTeamA.logo : teamShield
    let teamBShield = myTeamB ? myTeamB.logo : teamShield

    if (hasTeam) {
      if (myTeam === 'A') {
        teamAName = myTeamA ? myTeamA.name : `${tInContext('game.my_team', vm)}`
        teamBName = myTeamB ? myTeamB.name : `${tInContext('game.rival_team', vm)}`

        teamAShield = myTeamA ? myTeamA.logo : teamShield
        teamBShield = myTeamB ? myTeamB.logo : teamShield
      }

      if (myTeam === 'B') {
        teamAName = myTeamA ? myTeamA.name : `${tInContext('game.rival_team', vm)}`
        teamBName = myTeamB ? myTeamB.name : `${tInContext('game.my_team', vm)}`

        teamAShield = myTeamA ? myTeamA.logo : teamShield
        teamBShield = myTeamB ? myTeamB.logo : teamShield
      }
    }

    return {
      teamAName,
      teamBName,

      teamAShield,
      teamBShield,
    }
  }

  const getTeamName = (myTeams, myTeam, hasTeam = null) => {
    const { teamAName, teamBName } = getTeamsName(myTeams, myTeam, hasTeam)

    return myTeam === 'A' ? teamAName : teamBName
  }

  const getTeamShield = (myTeams, myTeam, hasTeam = null) => {
    const { teamAShield, teamBShield } = getTeamsName(myTeams, myTeam, hasTeam)

    return myTeam === 'A' ? teamAShield : teamBShield
  }

  const mapTeams = (playersA, playersB, team = null, myTeam = null, myTeams = []) => {
    if (!playersA || !playersA || playersA.length === 0 || playersB.length === 0) return []

    const { teamAName, teamBName } = getTeamsName(myTeams, myTeam, myTeam)

    const teams = [
      {
        id: 'A',
        players: playersA.some(e => e.nprd !== null) ? sortByNprd(playersA) : playersA,
        my_team: team === 'A',
        name: teamAName,
        logo: require('@/assets/images/svg/team-shield.svg'),
      },
      {
        id: 'B',
        players: playersB.some(e => e.nprd !== null) ? sortByNprd(playersB) : playersB,
        my_team: team === 'B',
        name: teamBName,
        logo: require('@/assets/images/svg/team-shield.svg'),
      },
    ]

    if (myTeam === 'B') return teams.reverse()

    return teams
  }

  return {
    // data
    paymentMethods,

    // mEthods
    fetchPaymentMethods,
    mapTeams,
    getTeamName,
    getTeamShield,
  }
}
