<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
      >
        <v-card
          color="white"
          width="100%"
          rounded="lg"
          :disabled="disabled"
          @click="onOpenMap"
        >
          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text pa-0 pb-5">
            <v-carousel
              height="205px"
              width="auto"
              hide-delimiter-background
              cycle
              class="rounded-lg rounded-b-0"
              :show-arrows="false"
            >
              <div>
                <v-carousel-item
                  v-for=" (item, index) in images"
                  :key="index"
                  :src="item"
                  eager
                />
              </div>
            </v-carousel>
          </v-card-text>

          <v-card-text class="d-flex justify-space-between align-center text-body-2 black--text">
            <div class="d-flex">
              <div>
                <div class="d-flex flex-column mb-3">
                  <span class="font-weight-bold">{{ facilityName }}</span>
                  <span
                    class="mt-1"
                    style="color: #3f3f3f;"
                  >
                    {{ facilityAddress }}
                  </span>
                </div>

                <div class="d-flex flex-column">
                  <span class="d-flex align-center font-weight-bold">
                    <v-icon
                      class="mr-1"
                      color="black"
                      size="18"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                    {{ $t('fields.field') }}
                  </span>
                  <span
                    class="mt-1"
                    style="color: #3f3f3f;"
                  >
                    {{ fieldName }}
                  </span>
                </div>
              </div>
            </div>

            <v-icon
              v-if="showArrow"
              color="#9b9b9b"
              class="ma-0"
              size="25"
            >
              {{ icons.mdiChevronRight }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiVideoOutline,
  mdiMessageText,
  mdiSeal,
  mdiChevronRight,
  mdiInformationOutline,
} from '@mdi/js'
import { computed, useSlots } from '@vue/composition-api'
import { openTab } from '@core/utils'

export default {
  name: 'FacilityCard',
  props: {
    facilityName: {
      type: String,
      default: '',
      required: true,
    },
    facilityAddress: {
      type: String,
      default: '',
      required: true,
    },
    fieldName: {
      type: String,
      default: '',
      required: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
    latitude: {
      type: Number,
      default: 0,
      required: true,
    },
    longitude: {
      type: Number,
      default: 0,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const slots = useSlots()

    const hasIconContent = computed(() => !!slots.icon)

    const onOpenMap = () => {
      if (props.latitude && props.longitude) openTab(`https://www.google.com/maps/search/?api=1&query=${props.latitude},${props.longitude}`, '_blank')
    }

    return {
      hasIconContent,

      onOpenMap,

      icons: {
        mdiVideoOutline,
        mdiMessageText,
        mdiSeal,
        mdiChevronRight,
        mdiInformationOutline,
      },
    }
  },
}
</script>
